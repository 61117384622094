let windowWidth = window.innerWidth,
    isShowTooltip = false,
    isShowModal = false,
    date,
    container,
    calendarWrapper,
    calendarControl,
    banner,
    bannerWrapper,
    priorityEvent = null,
    currentEvent = null,
    upcomingEvent = null,
    currentIndex = 0,
    sliderItems = [],
    slideWidth = null,
    sliderWidth = null,
    activeEvents = Array(),
    intervalId,
    prevMonthBtn,
    nextMonthBtn,
    json = null;
const STRING = 'string',
    NUMBER = 'number',
    currentDate = new Date(),
    monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
    monthNamesShort = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
    monthNamesDeclension = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

function debounce(func, ms) {
    let timer;

    return function wrapper() {
        if (timer) {
            clearTimeout(timer);
            timer = null;
        }

        timer = setTimeout(() => {
            func();
        }, ms);
    };
}

function hideModal() {

    document.querySelector('.modal').remove();
    document.body.classList.remove('hidden');

    isShowModal = false;
}

function generateModal(event) {

    isShowModal && hideModal();

    const modal = document.createElement('div');
    modal.className = 'modal';

    const modalInner = document.createElement('div');
    modalInner.className = 'modal__inner';
    modal.appendChild(modalInner);

    const modalClose = document.createElement('div');
    modalClose.className = 'modal__close close';
    modalClose.innerHTML = '<button><span></span><span></span></button>';
    modalInner.appendChild(modalClose);

    modalClose.addEventListener('click', function () {
        hideModal()
    })

    const modalWrap = document.createElement('div');
    modalWrap.className = 'wrap';
    const modalWrapBanner = event.content?.banner && event.content?.banner.length > 0 ? event.content.banner : './img/banners/steel-hunter.jpg'
    modalWrap.innerHTML = `
        <div class="banner">
            <img src="${modalWrapBanner}" alt="${event.title}" />
        </div>
    `;

    modalWrap.innerHTML += `<div class="information">
                <ul>
                    <li>
                        <span class="title">призовой</span>
                        <span class="value">${event.content?.award ? event.content.award : '-'}</span>
                    </li>
                    <li>
                        <span class="title">дата</span>
                        <span class="value">${getDateBetween(event, STRING)}</span>
                    </li>
                    <li>
                        <span class="title">время</span>
                        <span class="value">${event.content?.time ? event.content.time : '-'}</span>
                    </li>
                    <li>
                        <span class="title">место</span>
                        <span class="value">${event.content?.place ? event.content.place : '-'}</span>
                    </li>
                </ul>
            </div>
        `;

    modalWrap.innerHTML += `
        <div class="content">
            ${event.content?.html ? event.content.html : ''}
        </div>
    `;

    modalInner.appendChild(modalWrap);


    document.body.classList.add('hidden');

    document.body.appendChild(modal);

    setTimeout(() => {
        modal.classList.add('active');
    }, 100);

    isShowModal = true;
}

function hideTooltip() {

    calendarWrapper.querySelector('.calendar__tooltip').classList.remove('active');

    setTimeout(() => {
        calendarWrapper.querySelector('.calendar__tooltip').remove();
    }, 100);

    isShowTooltip = false;
}

function generateTooltip() {

    isShowTooltip && hideTooltip();

    const tooltipWrapper = document.createElement('div');
    tooltipWrapper.className = 'calendar__tooltip';

    const tooltipInner = document.createElement('div');
    tooltipInner.className = 'calendar__tooltip-inner';
    tooltipWrapper.appendChild(tooltipInner);

    const tooltipList = document.createElement('div');
    tooltipList.className = 'list';
    tooltipInner.appendChild(tooltipList);

    const tooltipButton = document.createElement('button');
    tooltipButton.className = 'calendar__tooltip-close close-tooltip-js';
    tooltipButton.textContent = 'Закрыть';
    tooltipInner.appendChild(tooltipButton);


    activeEvents.forEach((event, index) => {
        const tooltipItem = document.createElement('button');
        tooltipItem.className = index === currentIndex || index === currentIndex ? 'item item--active' : 'item';

        const itemIconWrap = document.createElement('span');
        itemIconWrap.className = 'item__icon';
        tooltipItem.appendChild(itemIconWrap);

        const itemIcon = document.createElement('img');
        const eventIcon = event?.content?.icon && event?.content.icon.length > 0 ? event?.content.icon : './img/events/default.png';
        itemIcon.src = eventIcon;
        itemIcon.alt = event.title;
        itemIconWrap.appendChild(itemIcon);

        const itemTitle = document.createElement('span');
        itemTitle.className = 'item__title';
        itemTitle.textContent = event.title;
        tooltipItem.appendChild(itemTitle);

        tooltipList.appendChild(tooltipItem);

        tooltipItem.addEventListener('click', function (e) {

            tooltipInner.querySelectorAll('.item').forEach(i => i.classList.remove('item--active'))
            tooltipItem.classList.add('item--active');

            currentIndex = activeEvents.findIndex((element) => element.id === event.id);
            goToSlide(currentIndex);
        })
    });

    calendarWrapper.appendChild(tooltipWrapper);

    setTimeout(() => {
        tooltipWrapper.classList.add('active');
    }, 100);

    isShowTooltip = true;
}

function getDateBetween(event, type) {
    const startDate = new Date(event.start);
    const endDate = new Date(event.end);

    const startDay = startDate.getDate();
    const endDay = endDate.getDate();

    let startMonth;
    let endMonth;

    if (type === STRING) {
        startMonth = monthNamesDeclension[startDate.getMonth()];
        endMonth = monthNamesDeclension[endDate.getMonth()];

        if (startDay === endDay && startDate.getMonth() === endDate.getMonth()) {
            return `${startDay} ${startMonth}`;
        } else {
            return `с ${startDay} ${startMonth} по ${endDay} ${endMonth}`;
        }

    } else if (type === NUMBER) {
        startMonth = startDate.getMonth() + 1 < 10 ? '0' + (startDate.getMonth() + 1) : (startDate.getMonth() + 1);
        endMonth = endDate.getMonth() + 1 < 10 ? '0' + (endDate.getMonth() + 1) : (endDate.getMonth() + 1);

        if (startDay === endDay && startDate.getMonth() === endDate.getMonth()) {
            return `${startDay} ${monthNamesDeclension[startDate.getMonth()]}`;
        } else {
            return `${startDay}.${startMonth} - ${endDay}.${endMonth}`;
        }
    }
}

function isEventNow(event) {
    const currentTime = new Date();
    return currentTime >= new Date(event.start) && currentTime <= new Date(event.end);
}

function eventsForDate(year, month, day) {
    const currentDate = new Date(year, month, day);
    return json.filter(event => {
        const eventStartDate = new Date(event.start);
        const eventEndDate = new Date(event.end);

        const eventStart = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate());
        const eventEnd = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate());

        return currentDate >= eventStart && currentDate <= eventEnd;
    });
}

function countDown(time) {

    let countDownDate = time;

    intervalId = setInterval(function () {

        countDownDate = countDownDate - 1000;

        if (countDownDate <= 0) {
            initEventsDashboard()
            stopCountDown()
        }

    }, 1000);

}

function stopCountDown() {
    clearInterval(intervalId);
}

function upcomingEventFunc() {

    for (const event of json) {
        if (isEventNow(event)) {
            currentEvent = event;
            if ((!priorityEvent || event.priority > priorityEvent.priority) && event.priority > 0) {
                priorityEvent = event;
                currentEvent = event;
            }
        }
        // else if ((!priorityEvent || (event.priority >= priorityEvent.priority && new Date(event.start) < new Date(priorityEvent.start))) && event.priority > 0) {
        //     priorityEvent = event;
        // }
    }

    if (!currentEvent) {

        const currentTime = new Date();

        for (const event of json) {
            if (new Date(event.start) > currentTime && (!upcomingEvent || new Date(event.start) < new Date(upcomingEvent.start))) {
                upcomingEvent = event;
            }
        }
    }

    if (priorityEvent) currentEvent = priorityEvent;

    let diffInMinutes;

    if (currentEvent) {

        activeEvents.push(currentEvent)

        // const eventEndDate = new Date(currentEvent.end);
        // const eventEnd = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate(), eventEndDate.getHours(), eventEndDate.getMinutes());

        // diffInMinutes = Math.abs(eventEnd - currentDate);

        // if (diffInMinutes <= 120000) countDown (diffInMinutes)

    } else if (upcomingEvent) {

        activeEvents.push(upcomingEvent)
        // const eventStartDate = new Date(upcomingEvent.start);
        // const eventStart = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate(), eventStartDate.getHours(), eventStartDate.getMinutes());        

        // diffInMinutes = Math.abs(eventStart - currentDate);

        // if (diffInMinutes <= 120000) countDown (diffInMinutes);

    } else {
        console.log("Мероприятий нет.");
    }
}


function generateEventIcon(itemEvents) {

    const itemEvent = document.createElement('div');
    itemEvent.className = 'item__events';

    let maxPriorityEvent = 0;

    switch (itemEvents.length) {
        case 0:
            console.log('Error');
            break;
        case 1:
            itemEvents.forEach(event => {
                const iconWrap = document.createElement('div');
                iconWrap.className = 'icon-wrap';

                const icon = document.createElement('img');
                const eventIcon = event?.content?.icon && event?.content.icon.length > 0 ? event?.content.icon : './img/events/default.png';
                icon.src = eventIcon;
                icon.alt = event.title;

                iconWrap.appendChild(icon);
                itemEvent.appendChild(iconWrap);
            })
            break;
        case 2:
            itemEvent.className += ' item__events--two';

            itemEvents.sort((a, b) => b.priority - a.priority);

            itemEvents.forEach(event => {
                const iconWrap = document.createElement('div');
                iconWrap.className = 'icon-wrap';

                if (event.priority > maxPriorityEvent) {

                    itemEvent.querySelectorAll('.icon-wrap').forEach(i => i.classList.remove('icon-wrap--priority'))
                    iconWrap.className += ' icon-wrap--priority';
                    itemEvent.className += ' has-priority';
                    maxPriorityEvent = event.priority;
                }

                const icon = document.createElement('img');
                const eventIcon = event?.content?.icon && event?.content.icon.length > 0 ? event?.content.icon : './img/events/default.png';
                icon.src = eventIcon;
                icon.alt = event.title;

                iconWrap.appendChild(icon);
                itemEvent.appendChild(iconWrap);
            })
            break;
        case 3:
            itemEvent.className += ' item__events--three';

            itemEvents.sort((a, b) => b.priority - a.priority);

            itemEvents.forEach(event => {
                const iconWrap = document.createElement('div');
                iconWrap.className = 'icon-wrap';

                if (event.priority > maxPriorityEvent) {
                    itemEvent.querySelectorAll('.icon-wrap').forEach(i => i.classList.remove('icon-wrap--priority'))
                    iconWrap.className += ' icon-wrap--priority';
                    itemEvent.className += ' has-priority';
                    maxPriorityEvent = event.priority;
                }

                const icon = document.createElement('img');
                const eventIcon = event?.content?.icon && event?.content.icon.length > 0 ? event?.content.icon : './img/events/default.png';
                icon.src = eventIcon;
                icon.alt = event.title;

                iconWrap.appendChild(icon);
                itemEvent.appendChild(iconWrap);
            })
            break;
        default:
            itemEvent.className += ' item__events--three';

            itemEvents.sort((a, b) => b.priority - a.priority);

            itemEvents.forEach((event, index) => {

                if (index > 1) return;

                const iconWrap = document.createElement('div');
                iconWrap.className = 'icon-wrap';

                if (event.priority > maxPriorityEvent) {
                    itemEvent.querySelectorAll('.icon-wrap').forEach(i => i.classList.remove('icon-wrap--priority'))
                    iconWrap.className += ' icon-wrap--priority';
                    itemEvent.className += ' has-priority';
                    maxPriorityEvent = event.priority;
                }

                const icon = document.createElement('img');
                const eventIcon = event?.content?.icon && event?.content.icon.length > 0 ? event?.content.icon : './img/events/default.png';
                icon.src = eventIcon;
                icon.alt = event.title;

                iconWrap.appendChild(icon);
                itemEvent.appendChild(iconWrap);
            })

            const countWrap = document.createElement('div');
            countWrap.className = 'icon-wrap icon-wrap--count';

            const count = document.createElement('span');
            count.textContent = '+' + (itemEvents.length - 2);

            countWrap.appendChild(count);
            itemEvent.appendChild(countWrap);

            break;
    }

    return itemEvent

}

function generateCell(cls, year, month, i) {

    const item = document.createElement('div');
    item.className = cls;

    const itemWrap = document.createElement('div');
    itemWrap.className = 'item';

    const itemInner = document.createElement('div');
    itemInner.className = 'item__inner';

    const itemDay = document.createElement('span');
    itemDay.className = 'item__day';

    itemDay.textContent = i;

    itemInner.appendChild(itemDay);
    itemWrap.appendChild(itemInner);
    item.appendChild(itemWrap);

    if (
        date.getFullYear() === currentDate.getFullYear() &&
        date.getMonth() === currentDate.getMonth() &&
        i === currentDate.getDate()
    ) {
        item.classList.add('calendar__item--selected');
    }

    item.addEventListener('click', function (e) {

        isShowTooltip && hideTooltip();

        if (e.target.closest('.calendar__item--previous')) {
            prevMonthBtn.click();
        }

        if (e.target.closest('.calendar__item--next')) {
            nextMonthBtn.click();
        }

    });

    const itemEvents = eventsForDate(year, month, i);

    if (itemEvents.length > 0) {
        item.classList.add('calendar__item--event');

        const icons = generateEventIcon(itemEvents)

        itemInner.appendChild(icons);

        item.addEventListener('click', function (e) {

            isShowTooltip && hideTooltip();

            if (e.target.closest('.calendar__item--other-month')) return;

            calendarWrapper.querySelectorAll('.calendar__item').forEach(i => i.classList.remove('calendar__item--active'));
            item.classList.add('calendar__item--active');
            activeEvents = itemEvents;

            currentIndex = 0;

            generateTooltip();
            renderBanner()
        })
    }

    return item;
}

function initCalendar(year, month) {
    container = calendarWrapper.querySelector('.calendar__container');
    container.innerHTML = '';

    date = new Date(year, month, 1);

    const startDay = date.getDay() === 0 ? 6 : date.getDay() - 1;

    const daysCount = new Date(year, month + 1, 0).getDate();
    const prevMonthDaysCount = new Date(year, month, 0).getDate();

    const monthHeader = document.querySelector('.calendar__control .navigation p');
    monthHeader.textContent = monthNames[month];

    for (let i = 0; i < startDay; i++) {

        let dayClassName = 'calendar__item calendar__item--other-month calendar__item--previous';

        if (date.getDay() === 0 || date.getDay() === 6) {
            dayClassName += ' calendar__item--weekend';
        }
        const item = generateCell(dayClassName, year, month - 1, prevMonthDaysCount - startDay + i + 1)

        container.appendChild(item);
    }



    for (let i = 1; i <= daysCount; i++) {

        let dayClassName = 'calendar__item';

        if (date.getDay() === 0 || date.getDay() === 6) {
            dayClassName += ' calendar__item--weekend';
        }

        const item = generateCell(dayClassName, year, month, i);

        container.appendChild(item);

        date.setDate(date.getDate() + 1);
    }


    const remainingDays = 42 - startDay - daysCount;

    date = new Date(year, month + 1, 1);

    for (let i = 1; i <= remainingDays; i++) {

        let dayClassName = 'calendar__item calendar__item--other-month calendar__item--next';

        if (date.getDay() === 0 || date.getDay() === 6) {
            dayClassName += ' calendar__item--weekend';
        }

        const item = generateCell(dayClassName, year, month + 1, i);

        container.appendChild(item);

        date.setDate(date.getDate() + 1);
    }

}

function generateBanner() {

    banner.innerHTML = '';

    const uniqueActiveEvents = new Set(activeEvents);

    uniqueActiveEvents.forEach(event => {
        const item = document.createElement('div');
        item.className = 'item';
        item.setAttribute('data-bg', event.content?.banner);

        if (window.innerWidth < 1024) item.style.width = bannerWrapper.clientWidth + 'px';

        const itemHeading = document.createElement('div');
        itemHeading.className = 'item__heading';
        item.appendChild(itemHeading);
        !item.classList.contains('active') && item.addEventListener('click', function (e) {

                currentIndex = activeEvents.findIndex((element) => element.id === event.id);

                // tooltipInner.querySelectorAll('.item').forEach(i => i.classList.remove('item--active'))
                // tooltipItem.classList.add('item--active');

                goToSlide(currentIndex);

                // console.log(e);
            })

            !item.classList.contains('active') && item.addEventListener('click', function (e) {

                currentIndex = activeEvents.findIndex((element) => element.id === event.id);

                // tooltipInner.querySelectorAll('.item').forEach(i => i.classList.remove('item--active'))
                // tooltipItem.classList.add('item--active');

                goToSlide(currentIndex);

                // console.log(e);
            })

        const itemTitle = document.createElement('p');
        itemTitle.textContent = event.title;
        itemHeading.appendChild(itemTitle);

        if (event.content?.award && event.content?.award.length > 0) {
            const itemAvard = document.createElement('p');
            itemAvard.className = 'award';
            itemAvard.textContent = event.content?.award;
            itemHeading.appendChild(itemAvard);
        }

        const itemContentWrap = document.createElement('div');
        itemContentWrap.className = 'item__image';
        item.appendChild(itemContentWrap);

        const eventStartDate = new Date(event.start);
        const eventEndDate = new Date(event.end);

        const eventStart = new Date(eventStartDate.getFullYear(), eventStartDate.getMonth(), eventStartDate.getDate(), eventStartDate.getHours(), eventStartDate.getMinutes());
        const eventEnd = new Date(eventEndDate.getFullYear(), eventEndDate.getMonth(), eventEndDate.getDate(), eventEndDate.getHours(), eventEndDate.getMinutes());

        if ((event?.stream?.stream && event?.stream?.stream.length > 0) && currentDate >= eventStart && currentDate <= eventEnd) {
            const itemIframe = document.createElement('iframe');
            itemIframe.title = event.title;
            itemIframe.setAttribute('frameborder', 0);
            itemIframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share';
            itemIframe.allowfullscreen = true;
            itemIframe.src = event?.stream.stream;
            itemContentWrap.appendChild(itemIframe);
        } else {
            const itemImg = document.createElement('img');
            itemImg.alt = event.title;
            const itemBanner = event.content?.banner && event.content?.banner.length > 0 ? event.content.banner : './img/banners/steel-hunter.jpg'
            itemImg.src = itemBanner;
            itemContentWrap.appendChild(itemImg);
        }

        const itemFooter = document.createElement('div');
        itemFooter.className = 'item__footer';
        item.appendChild(itemFooter);

        if (event.stream) {
            let streamsLink = `
                <div class="streams__inner">
            `;
            for (const key in event.stream) {
                if (event.stream[key] && key != 'stream') {
                    streamsLink += `
                            <a href="${event.stream[key]}">
                                <svg class="svg-icon users">
                                    <use xlink:href="./img/sprites.svg#${key}"></use>
                                </svg>
                            </a>
                        `;
                }
            }
            streamsLink += `</div>`;

            const itemStreams = document.createElement('div');
            itemStreams.className = 'streams';
            itemStreams.innerHTML = streamsLink;
            itemFooter.appendChild(itemStreams);
        } else {
            if (innerWidth >= 1024) {
                const itemCountDown = document.createElement('div');
                itemCountDown.className = 'countdown';
                itemFooter.appendChild(itemCountDown);

                const itemCountDownPlaceholder = document.createElement('p');
                itemCountDownPlaceholder.textContent = getDateBetween(event, NUMBER);
                itemCountDown.appendChild(itemCountDownPlaceholder);
            }
        }

        const itemButton = document.createElement('button');
        itemButton.textContent = 'подробнее';
        itemButton.className = 'button button--outline modal-js';
        itemFooter.appendChild(itemButton);

        itemContentWrap.addEventListener('click', () => itemContentWrap.classList.contains('modal-js') && generateModal(event))
        itemButton.addEventListener('click', () => generateModal(event))

        banner.appendChild(item);
    });

}

function initEventsDashboard() {

    for (const event of json) {
        if (isEventNow(event)) {
            activeEvents.push(event)
        }
    }

    initCalendar(currentDate.getFullYear(), currentDate.getMonth());
    initBanner();

    if (window.innerWidth < 1024) calendarOffset()

    prevMonthBtn = document.querySelector('.nav-left');
    prevMonthBtn.addEventListener('click', () => {
        currentDate.setMonth(currentDate.getMonth() - 1);

        getEvents(currentDate.getFullYear(), currentDate.getMonth())
            .then(data => {
                json = data ? data : []

                initCalendar(currentDate.getFullYear(), currentDate.getMonth());
            })
    });
    calendarControl.insertBefore(prevMonthBtn, calendarControl.firstChild);


    nextMonthBtn = document.querySelector('.nav-right');
    nextMonthBtn.addEventListener('click', () => {
        currentDate.setMonth(currentDate.getMonth() + 1);

        getEvents(currentDate.getFullYear(), currentDate.getMonth())
            .then(data => {
                json = data ? data : []

                initCalendar(currentDate.getFullYear(), currentDate.getMonth());
            })
    });
    calendarControl.appendChild(nextMonthBtn);

}

function calendarOffset() {
    container.scrollLeft = document.querySelector('.calendar__item--selected').offsetLeft - 32;
}

function goToSlide(index) {
    if (index < 0) {
        index = sliderItems.length - 1;
    } else if (index >= sliderItems.length) {
        index = 0;
    }

    currentIndex = index;

    sliderItems.forEach(el => {
        el.classList.remove('active')
        el.querySelector('.item__image').classList.remove('modal-js');
    });
    sliderItems[currentIndex].classList.add('active');
    sliderItems[currentIndex].querySelector('.item__image').classList.add('modal-js');

    const image = sliderItems[currentIndex].getAttribute('data-bg');

    document.querySelector('.events__bg').style.backgroundImage = 'url(' + image + ')';

    let offset;

    if (window.innerWidth > 1680) {
        offset = -currentIndex * slideWidth + (bannerWrapper.clientWidth - slideWidth) / 2;
    } else {
        offset = -currentIndex * slideWidth;
    }

    banner.style.transform = `translateX(${offset}px)`;
}

function renderBanner() {

    generateBanner();

    sliderItems = banner.querySelectorAll(".item");

    slideWidth = window.innerWidth < 1024 ? bannerWrapper.clientWidth : sliderItems[0].clientWidth;
    sliderWidth = sliderItems.length * slideWidth;

    banner.style.width = `${sliderWidth}px`;

    goToSlide(currentIndex);

}

function initBanner() {

    if (!activeEvents || activeEvents.length === 0) return

    upcomingEventFunc();

    if (currentEvent) {
        currentIndex = activeEvents.findIndex((element) => element.id === currentEvent.id);
    } else if (upcomingEvent) {
        currentIndex = activeEvents.findIndex((element) => element.id === upcomingEvent.id);
    } else {
        currentIndex = 0;
    }

    renderBanner();

}

function createNews(data) {
    const newsWrap = document.querySelector('#news .news__list');

    data.forEach(i => {

        const news = document.createElement('div');
        news.classList.add('item');

        const newsImageWrap = document.createElement('div')
        newsImageWrap.classList.add('item__image');
        news.appendChild(newsImageWrap);

        const newsImage = document.createElement('img');
        newsImage.src = i.img;
        newsImage.alt = i.title;
        newsImageWrap.appendChild(newsImage);

        const newsContent = document.createElement('div');
        newsContent.classList.add('item__content');
        news.appendChild(newsContent);

        if (i.isLesta) {
            const newsLabel = document.createElement('p');
            newsLabel.classList.add('label');
            newsLabel.innerText = 'Турнир разработчика';
            newsContent.appendChild(newsLabel);
        }

        const newsTitle = document.createElement('p');
        newsTitle.classList.add('title');
        newsTitle.innerText = i.title;
        newsContent.appendChild(newsTitle);

        const newsDate = document.createElement('p');
        newsDate.classList.add('date');
        newsDate.innerText = i.additional;
        newsContent.appendChild(newsDate);

        const newsLink = document.createElement('a');
        newsLink.setAttribute('href', i.link);
        newsLink.setAttribute('target', "_blank");
        news.appendChild(newsLink);

        newsWrap.appendChild(news);

    });
}

function getEvents(year, month) {

    const url = !isNaN(Number(year) && Number(month)) ? `https://cybersport-data.tanki.su/mt-cyber-admin/json-files/events_${month + 1}_${year}.json` : 'https://cybersport-data.tanki.su/mt-cyber-admin/json-files/events.json'
    return fetch(url)
        .then(response => {
            if (!response.ok) {
                return []
            }
            return response.json();
        });
}

function getNews() {
    const url = `https://cybersport-data.tanki.su/mt-cyber-admin/json-files/news.json`;

    return fetch(url)
        .then(response => {
            if (!response.ok) {
                return []
            }
            return response.json();
        });
}


document.addEventListener('keydown', function (e) {
    if (e.key == 'Escape' && isShowTooltip) {
        hideTooltip();
    }
});

document.addEventListener("click", function (e) {
    const target = e.target;
    if (!e.composedPath().includes(calendarWrapper) && isShowTooltip || target.closest('.close-tooltip-js')) hideTooltip();
    if (isShowModal && !target.closest('.modal__inner') && !target.closest('.modal-js')) hideModal();
});

window.addEventListener('resize', debounce(function (e) {
    if (window.innerWidth != windowWidth) {
        calendarWrapper && banner && initEventsDashboard();
        windowWidth = window.innerWidth;
    }

}), 2000);

window.addEventListener('load', function (e) {
    calendarWrapper = document.querySelector('.calendar');
    calendarControl = document.querySelector('.calendar__control .navigation');

    bannerWrapper = document.querySelector(".banner");
    banner = bannerWrapper && bannerWrapper.querySelector(".banner__inner");

    getEvents()
        .then(data => {
            json = data;
            json && calendarWrapper && banner && initEventsDashboard();
        })
        .catch(error => {
            console.error(error);
        });

    getNews()
        .then(data => {
            createNews(data)
        })
        .catch(error => {
            console.error(error);
        });

})